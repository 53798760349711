import Vue from 'vue'
function handleScreen() {
  const m = detectZoom();
  document.body.style.zoom = 100 / Number(m);
}
function detectZoom() {
  let ratio = 0,
    screen = window.screen,
    ua = navigator.userAgent.toLowerCase();
  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  } else if (~ua.indexOf('msie')) {
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI;
    }
  } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
    ratio = window.outerWidth / window.innerWidth;
  }
  if (ratio) {
    ratio = Math.round(ratio * 100);
  }
  return ratio;
}
function adaptive() {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    Vue.prototype.isPc = false;
  } else {
    Vue.prototype.isPc = true;
  }
  if (Vue.prototype.isPc) {
    handleScreen();
    document.body.setAttribute("class", "isPc")
  } else {
    document.body.setAttribute("class", "isMobile")
  }
}
function stopZoom() {
  const keyCodeMap = {
    // 91: true, // command
    61: true,
    107: true, // 数字键盘 +
    109: true, // 数字键盘 -
    173: true, // 火狐 - 号
    187: true, // +
    189: true, // -
  };
  // 覆盖ctrl||command + ‘+’/‘-’
  document.onkeydown = function (event) {
    const e = event || window.event;
    const ctrlKey = e.ctrlKey || e.metaKey;
    if (ctrlKey && keyCodeMap[e.keyCode]) {
      e.preventDefault();
    } else if (e.detail) { // Firefox
      event.returnValue = false;
    }
  };
  // 覆盖鼠标滑动
  document.body.addEventListener('wheel', (e) => {
    if (e.ctrlKey) {
      if (e.deltaY < 0) {
        e.preventDefault();
        return false;
      }
      if (e.deltaY > 0) {
        e.preventDefault();
        return false;
      }
    }
  }, { passive: false });
}
// adaptive()
handleScreen()
stopZoom()
// window.onresize = function () {
//     adaptive()
// }
