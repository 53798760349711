<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="true" :show-close="false"
    >
      <div slot="title" class="dialog-footer">
        <div style="padding: 0 20px 26px 0;border-bottom: 1px solid #EAEAEA;">
          <el-row>
            <el-col :span="2" style="text-align: left;font-size: 18px;cursor: pointer;color: #3753E4" @click="isShow=false">返回</el-col>
            <el-col :span="22" style="font-size: 18px;font-weight: bold;text-align: center">{{$t('M001')}}</el-col>
          </el-row>
        </div>

      </div>
      <div>

        <div class="cell" style="text-align: center;color: #000000;padding: 21px 0 15px 0">{{ $t('M002')||'拖动下方滑块完成拼图' }}</div>
        <div class="cell" style="position: relative">
          <div style="width: 250px;margin: 0 auto;min-height: 250px" >
            <drag-verify-img-chip
              v-if="SX"
              ref="dragVerify"
              :imgsrc="images"
              :is-passing.sync="isPassing"
              :show-refresh="true"
              :text="$t('M003')||'滑动验证'"
              :success-text="$t('M004')||'验证通过'"
              handler-icon="el-icon-video-pause"
              success-icon="el-icon-circle-check"

              @refresh="handleRefresh"
              @passcallback="handlePass"
              @passfail="handleFail"
            />
          </div>
          <div style="position: absolute;bottom: 0px;right: 20px" @click="HRefresh"><i class="el-icon-refresh-right"></i> </div>

        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import one from '@/components/dragImgChip/images/one.png'
import two from '@/components/dragImgChip/images/two.png'

export default {
    name: 'LdAddress',
    components: {
        // 图片滑块组件(拼图)
        dragVerifyImgChip: () => import('@/components/dragImgChip/dragVerifyImgChip.vue')
    },
    data() {
        return {
            isShow: false,
            isPassing: false,
            images: '',
          SX:true
        }
    },
    created() {
    },
    methods: {
        handleReset: function () {
            this.isPassing = false
            this.$refs.dragVerify.reset()
        },
        handlePass: function () {
          this.$message({
                message: this.$t('M004'), // '验证通过',
                type: 'success'
            })
            var self = this
            setTimeout(function () {
                self.$emit('selected')
                self.toggle()
            }, 1000)
        },
        handleRefresh() {
          console.log(this,'ccccccccccc')
            let im=JSON.parse(JSON.stringify(this.images))
            this.images=''
            this.images = im == one ? two : one
             this.$forceUpdate()
        },
        HRefresh(){
          this.SX=false
            this.handleRefresh()
          let that=this
          setTimeout(function (){
            that.SX=true
          },30)
          // this.SX=true
        },
        handleFail: function (res) {
            console.log(res)
        },
        toggle: function () {
            this.isShow = !this.isShow
            if (this.isShow) {
                this.images = two
            }
            this.isPassing=false
            this.HRefresh()
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog{
  width: 806px;
  min-height: 350px;
}
::v-deep .el-dialog__body{
  padding:0 21px 21px 21px;
}
//::v-deep .el-dialog__header{
//  display: none;
//}
</style>
