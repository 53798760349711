export default {
  M001: "Security verification",
  M002: "Drag the lower slider to complete the puzzle",
  M003: "Sliding verification",
  M004: "Pass the verification",
  M005: "Verification passed, more than 80% users",
M006: "If the verification fails, drag the slider to merge the floating image correctly",
  A001: "Home",
  A002: "Cloud profile",
  A003: "solution",
  A004: "system integrating",
  A005: "News on the cloud",
  A006: "download center",
  A007: "Contact Us",
  A008: " Tour Reserve",
  A009: "Boss",
  A010: "Department Manager",
  A011: "Finance Accountant",
  A012: "Trip Generation",
  A013: "View More",
  A014: "Information ecurity",
  A015: "Exclusive offer",
  A016: "Retrograde transparent",
  A017: "Intimate travel",
  A018: "Airline",
  A019: "Contracted hotel",
  A020: "Vehicle platform",
  A021: "International contract hotel",
  A022: "International coverage area",
  A023: "Domestic contracted hotel",
  A024: "Domestic covered cities",
  A025: "Cloud Shaanxi booking mobile terminal, let you travel more convenient",
  A026: "company profile",
  A027: "enterprise cooperation",
  A028: "company profile",
  A029: "air ticket",
  A030: "use the car",
  A031: "railway ticket",
  A032: "visa",
  A033: "hotel",
  A034: "insurance",
  A035: "address",
  A036: "Other platforms",
  A037: "Official Accounts",
  A038: "APP scan code download",
  A039: "Customer-oriented, enterprise as the carrier, the spirit of innovation, to provide personalized services for enterprises",
  A040: "Corporate travel booking platform",
  A041: "login",
  A042: "Corporate travel booking platform",
  A043: "Multiple settlement modes",
  A044: "Speed change ticket refund",
  A045: "Financial data are recorded",
  A046: "Travel speed approval",
  A047: "Provide solutions according to customer requirements",
  A048: "The lowest fare on the whole network",
  A049: "computer",
  A050: "mobile terminal",
  A051: "Business travel is easy on the cloud",
  A052: "The best ticket price on the whole network",
  A053: "Convenient approval process",
  A054: "Convenient approval process visa one-click processing",
  A055: "Save on travel expenses",
  A056: "Reduce administrative cost",
  A057: "Improve enterprise efficiency",
  A058: "Scan code to download mobile terminal",
  A059: "Iata certification",
  A060: "Business qualification certificate",
  A061: "Book a plane ticket",
  A062: "Book a hotel",
  A063: "Book a train ticket",
  A064: "Go to book a car",
  A065: "More than 100 domestic and international airlines, ticket real-time inquiry, multi-channel booking, large customer agreement hosting change flexible, and Air China letter IBE data direct connection, within one minute quick quotation, 3-5 minutes out of the ticket, with one-way, round-trip, normal shipping, special shipping, connecting booking function",
  A066: "Provide more than 40,000 hotels in China and more than 1,600 hotels in the world to make reservations, real-time query of room status booking, to ensure that there is room in the store, the room supply is guaranteed. Enterprise agreement hotel trusteeship, housing price, star, city control. Optimize the agreement hotel, strictly implement the hotel travel policy, hotel price comparison system, preferentially recommend the preferred hotel, tracking and analyzing the travel policy that does not meet the requirements.",
  A067: "The system is fully connected to the resources of 12306 platform, and can check and book high-speed rail, bullet train and ordinary train tickets from all over the country. Support 12306 account booking, support online booking/return/change, residual ticket monitoring, grab tickets, seat.",
  A068: "A variety of vehicle models for travel and business trips, to meet the needs of enterprises and customers in different scenarios; 7*24 hours online quality service guarantee. Access to domestic mainstream car service providers (such as Didi Dache, etc.) covering major cities across the country.",
  A069: "Aggregate resources to improve audit efficiency",
  A070: "Compare prices quickly to improve booking efficiency",
  A071: "Control the whole process to improve cost compliance",
  A072: "No money, no borrowing, enhance the travel experience",
  A073: "Information compliance and transparency, real-time data viewing",
  A074: "Monthly settlement, deposit, cash a variety of settlement modes",
  A075: "Travel solutions",
  A076: "Travel process",
  A077: "Butler services",
  A078: "Flight official data access",
  A079: "Real-time access to the Civil Aviation Information System of China to ensure synchronous docking of flight price data",
  A080: "Hotel official data access",
  A081: "Real-time access to hotel group, individual hotel room rate system, at any time to maintain the price",
  A082: "Automatic price comparison for reservation",
  A083: "For collection and payment hotels, the system automatically compares the room rates of large reservation centers to protect the interests of customers",
  A084: "MIS data processing",
  A085: "All access and outgoing data come from the headquarters MIS system, through which data exchange is completed to ensure the mix and match of Internet access for call centers and assigned agents",
  A086: "CRM personalized service",
  A087: "The perfect CRM customer relationship management system can identify customers, analyze and record their travel habits and special requirements, and provide customers with more personalized services",
  A088: "Customer trip care system",
  A089: "Customer care system: short message notification after booking, ticket issue, pre-departure reminder, delay reminder and arrival weather forecast",
  A090: "7 x 24 hour operation center",
  A091: "booking on the Internet",
  A092: "The travel management and reservation system clients are nested",
  A093: "draw rein",
  A094: "Improve enterprise efficiency",
  A095: "Enhance employees' travel experience",
  A096: "Professional data analysis",
  A097: "Enterprise payment and unified settlement reduce the amount of invoice processing by more than 95%. The whole process management of enterprise expenditure saves more than 30% of travel costs",
  A098: "Enterprise payment and unified settlement reduce the invoice processing volume by more than 95%",
  A099: "One-stop consumption 100% experience offline consumption reimbursement more intelligent and convenient",
  A100: "Real-time, multidimensional data reporting helps enterprises improve management efficiency by 100%",
  A101: "information",
  A102: "After-sales consultation",
  A103: "company mail",
  A104: "company address",
  A105: "Intelligent diagnosis, second level answers, for you to recommend the best solution or match the appropriate human services",
  A106: "7 x 24 hours hotline service, to provide you with professional after-sales support",
  A107: "Intelligent customer service online 7 x 24 hours",
//   A108: "Intelligent customer service online 7 x 24 hours",
  A109: "Consultation time (9:00-24:00)",
  A110: "Mobile download",
  A111: "Daily new user downloads",
  A112: "User praise rate",
  A113: "Monthly platform order volume",
  A114: "Yunshang International is a professional management service company under Shaanxi Yunshang Technology Group Co., Ltd. which focuses on customer business trips",
  A115: "The company was founded in 2017, with a registered capital of 30 million yuan, approved by the State Administration for Industry and Commerce. It is one of the air ticket agents, and has been awarded the first-class qualification of air passenger transport and cargo by the Civil Aviation Administration of China, and the first-class qualification of the International Air Transport Association (IATA). The company joined the China Aviation Association in 2021. Become the official ticket agent with domestic and international airlines to establish a cooperative relationship, with the industry's powerful peers to achieve resource sharing, and with the Sanqin City Daily reached a strategic cooperation.",
  A116: "Adhering to the purpose of 'satisfactory service above all else', Yunshang International provides customers with high-quality air tickets, hotels, ticket booking as one of the e-commerce service platform.",
  A117: "Customer-oriented, enterprise as the carrier, the spirit of innovation, to provide personalized services for enterprises",
  A118: "The platform integrates intelligent travel control operations, reduces the cost of travel control for enterprises to the greatest extent, improves travel efficiency, provides enterprises with one-stop travel solutions, and effectively solves the pain points encountered by enterprises in the process of travel.",
  A119: "1.Travel expenses exceed the standard, and the capital turnover pressure of enterprises is great. The traditional travel management expenses are huge. Pain point",
  A120: "2.The traditional travel management is too extensive and irregular. It is impossible to master the travel itinerary of business travelers, which leads to the phenomenon of false reporting and multiple reporting.",
  A121: "3.The high cost of travel can not be formally controlled traditional business trip because the enterprise travel management mode is relatively old, resulting in a lot of cost waste and human input. Pain point 4: Long reimbursement cycle of personal funds for employees on business trips. Original business trips require employees to pay in advance, and can only be reimbursed with bills after business trips. process",
  A122: "Cloud Business Travel integrates multiple travel functions such as ticket purchase, accommodation, approval and car use, providing users with a more convenient and efficient travel experience.",
  A123: "Ticket purchase covers: air tickets, train tickets, ticket channels are purchased from professional platforms, ticket prices are the lowest in the whole network, at the same time to support the rapid change, refund function; Hotel: the platform signed cooperation with a number of domestic and international hotels, anytime and anywhere booking; The car platform signed 'Cao Cao Private Car', 'Shouqi car' and other authoritative car platform, to provide users with a reassuring car experience; At the same time, the platform supports visa processing for countries and regions around the world, providing users with the most convenient and secure travel experience.",
  A124: "7 days *24 hours reservation service center, 60 professional seat telephone call center;",
  A125: "Advanced customer management system, comprehensive record customer data;",
  A126: "The full-time customer service manager ensures personal and personalized service for customers. The whole recording management is convenient for me",
  A127: "Management and monitoring of service quality and service attitude;",
  A128: "Air China letter provides authoritative civil aviation information network reservation interface;",
  A129: "National online hotel reservation system to realize real-time quotation and reservation functions;",
  A131: "Network and customer management system synchronization, to achieve automatic data conversion;",
  A132: "Customers can search and book air tickets and hotels through the network in real time, and book national airport VIP service and commercial vehicle pickup service in real time, convenient and fast",
  A133: "In combination with the corporate travel system, a personalized travel management system is developed, which is directly embedded in the employee work platform.",
  A134: "In combination with the corporate travel system, a personalized travel management system is developed, which is directly embedded in the employee work platform. Through the system, employees can directly book air tickets, hotels, airport services, car rental services;",
  A135: "Realize the seamless connection of all service products, customers stay at home, enjoy a series of professional travel services;",
  A137: "Prior control",
  A138: "Organization management, differential configuration",
  A139: "Approval configuration, budget management",
  A140: "Consumption payment",
  A141: "Enterprise payment, real-time order details",
  A142: "Fee attribution, message notification",
  A143: "Unified settlement/financial entry",
  A144: "Standard order, uniform invoice",
  A145: "Generate financial data and record it once",
  A146: "Data analysis",
  A147: "Product line consumption analysis, project consumption analysis",
  A148: "Save analysis, waste analysis",
  A149: "one-stop shopping",
  A150: "Travel consumption one stop to solve",
  A151: "Avoid cross-platform consumption",
  A152: "Strict selection service",
  A153: "Massive supplier strict selection of resources",
  A154: "You can always find the low price you want",
  A155: "Recheneinheit",
  A156: "Monthly deposit service",
  A157: "Avoid repeated requests for payment, do not occupy the capital flow of the enterprise",
  A158: "Priority service",
  A159: "Priority waiting and ticket, no ticket grab tickets",
  A160: "Printing of domestic electronic boarding pass before boarding",
  A161: "Top service",
  A162: "The account manager assists all the way",
  A163: "Can be customized individually, different from the ordinary service standard",
  A164: "VIP service",
  A165: "7*24 hours online service",
  A166: "2 to 1 wechat group processing, high-speed response",
  A167: "Manual service consulting 7 x 15 hours",
  A168:"Consultation time（9:00-24:00）",
  A169:"As a professional business travel system provider, Yunshang Technology Group has developed its own system and has owned a number of online business travel booking and management systems with software copyright",
  A170:"Yunshangtech Group, with a registered capital of 30 million yuan, provides enterprise travel as the core, through employee travel big data analysis, optimize enterprise travel management, to provide enterprises with domestic and foreign, air tickets, hotels, train tickets, travel vehicles, airport lounge and other all-round convenient one-stop service platform. Through information solutions, the integration of resources such as centralized procurement of enterprise key customers can save cash flow and enterprise operating costs and realize rational utilization of resources.",
  A171:"Yunshang Technology Group was approved by the State Administration for Industry and Commerce, one of the air ticket agents, by the Civil Aviation Administration of China as air passenger transport, cargo first-class qualification, International Air Transport Association (IATA) certification.",
  A172:"Experience",
  A173:"Support visa processing in many countries around the world, data submission, processing process, one-to-one visa specialist guidance and tracking, improve the efficiency of visa processing",
  A174:"With a number of well-known insurance companies signed cooperation, for your travel safety escort, to provide the most secure, the most reliable travel protection",
  H001:"Quickly address pain points in the travel industry",
  H002:"Efficiently solve the problems faced by enterprises/organizations in the process of travel management",
  H003:"Five service products",
  H004:"Five quality characteristic service products",
  H005:"Independently developed business travel system",
  H006:"Reduce travel costs, improve work efficiency, and enhance the travel experience",
  H007:"See more solutions",
  H008:"Quickly understand the cloud",
  H009:"Cloud information, news quickly understand",
  H010:"Authoritative quality partner",
  H011:"All aspects of high-quality resource partners",
  H012:"business cooperation",
  H013:"group introduction",
  H014:"business distribution",
  H015:"development history",
  H016:"enterprise qualification",
  H017:"Business travel system",
  H018:"product program",
  H019:"Cost control scheme",
  H020:"Settlement scheme",
  H021:"Interconnection scenario",
  H022:"Docking cycle",
  H023:"phase center",
  H024:"Mobile download",
  H025:"Cloud profile",
  H026:"solution",
  H027:"system integrating",
  H028:"News on the cloud",
  H029:"download center",
  H030:"attention",
  H031:"24-hour hotline",
  H032:"know more about",
  H035:"Expand all solutions",
  H036:"Put away all solutions",
  H037:"Travel booking process",
  H038:"Standardized, programmed booking process, travel more efficient and convenient",
  H039:"Fund settlement solution",
  H040:"Employees do not need to pay, one ticket reimbursement settlement",
  H041:"Fill in the connection intention",
  H042:"Quick access to group information",
  H043:"Industry news",
  H044:"First look at business travel news",
  H045:"system function",
  H046:"系统功能",

  H047:"Statistical analysis of tickets",
  H048:"Real-time ticketing, profit statistics",
  H049:"Travel order processing",
  H050:"Maintenance and management of travel orders",
  H051:"Asking for a bill",
  H052:"Travel ticket management",

  H053:"Travel tickets",
  H054:"Air tickets, train tickets, hotel online booking",
  H055:"travel management",
  H056:"Personal travel order inquiry management",
  H057:"Travel approval",
  H058:"Employee travel order approval management",

  H059:"Debid setting",
  H060:"Travel parameters, rules set management",
  H061:"Business travel analysis",
  H062:"Real-time statistical analysis of travel consumption data",
  H063:"financial management",
  H064:"Statement, expense account operation management",

  H065:"back homepage",
  H066:"Back to solution",
  H067:"Enterprise WeChat",
};
