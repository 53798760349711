<template>
  <div id="app">
    <router-view/>
    <div class="HSlloBox">
      <div class="HSllo">
        <div>
          <el-popover
            width="200"
            placement="left-start"
            trigger="hover"
           >
            <div>
              <div>客服电话</div>
              <div v-if="$store.state.KFData">
                {{ $store.state.KFData.param1 || ''}}
              </div>
            </div>
            <div class="HSlloBoxOne" slot="reference"> </div>
          </el-popover>
        </div>


        <div @click="Hpartner"></div>
        <div @click="HScroll"></div>
      </div>
    </div>
    <partner :dialogFormVisible="dialogFormVisible" @closeDialogFormVisible="closeFlage"></partner>
  </div>
</template>
<script>
import partner from "@/components/partner.vue";
import {systemParamKF} from "@/api/home";

export default {
  components:{partner},
  data(){
    return{
      Time:null,
      dialogFormVisible:false,
      KFData:null

    }
  },
  created() {
  this.HKF()
  },
  methods:{
    HKF(){
      let that=this
      systemParamKF().then(res=>{
          this.$store.state.Hdata=res.result
        res.result.forEach(item=>{
          if(item.paramCode=='kf_lxdh') that.$store.state.KFData=item
          if(item.paramCode=='bottom_dz_lxdh_yx') that.$store.state.bottomData=item
          if(item.paramCode=='download_ewm_gzh') that.$store.state.HGzh=item
          if(item.paramCode=='download_ewm_wx') that.$store.state.Hwx=item
        })
        // this.KFData=res.result.find(red=>red.paramCode=='kf_lxdh')
        // this.bottomData= this.$store.state.Hdata.find(red=>red.paramCode=='bottom_dz_lxdh_yx')
        // this.HGzh=this.$store.state.Hdata.find(red=>red.paramCode=='download_ewm_gzh')
        // this.HKF=this.$store.state.Hdata.find(red=>red.paramCode=='kf_lxdh')
      })
    },
    Hpartner(){
        this.dialogFormVisible=true
    },
    closeFlage(item){
      this.dialogFormVisible=item
    },
   HScroll(){
      let that=this
    this.Time=setInterval(function(){
       var scrollTop=document.documentElement.scrollTop||document.body.scrollTop;
       var ispeed=Math.floor(-scrollTop/6);
       if(scrollTop==0){
         clearInterval(that.Time);
       }
       document.documentElement.scrollTop=document.body.scrollTop=scrollTop+ispeed;
     },10)
   },
 }
}
</script>

<style lang="scss">
#app {
  padding: 0;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1900px;
}
.HSlloBox{
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 100000;
}

.HSllo{
  background: url(@/assets/right.png) center center no-repeat;
  background-size: 100%;
  width: 80px;
  height: 280px;

  >div{
    height: 33%;
    cursor: pointer;
    width: 100%;
  }
  .HSlloBoxOne{
    width: 100%;
    height: 100%;
  }
  //>div:nth-child(1),.HSlloBoxOne{
  //  span{
  //    display: inline-block;
  //    height: 100%;
  //    width: 100%;
  //  }
  //}
}
</style>
