import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
// https://gwadmin.ystmc.com/openapi/jeecg-boot/api
let param={
  url:'https://gwadmin.ystmc.com/openapi/',
  suffixUrl:'jeecg-boot/api'
  // url:'http://10.40.92.183:21002/',
  // suffixUrl:'jeecg-boot/api'
}
// create an axios instance
const service = axios.create({
  baseURL:param.url+param.suffixUrl ,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(

  response => {
    const res = response.data
      return res
  },
  error => {
    console.log(error,'vvv')

    Message({
      message: error.message=='timeout of 5000ms exceeded'?'请求超时':error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
