import request from './request'
export function newsList(data) {
  return request({
    url: 'news/list',
    method: 'get',
    params:data
  })
}

export function apply(data) {
  return request({
    url: 'cooperateApply/apply',
    method: 'get',
    params:data
  })
}
// 详情
export function newsDetail(data) {
  return request({
    url: 'news/queryById',
    method: 'get',
    params:data
  })
}
//客服
export function systemParamKF() {
  return request({
    url: 'systemParam/list',
    method: 'get',
  })
}
