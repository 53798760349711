import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navIndex:0,
    KFData:{
      param1:'',
      param2:'',
      param3:'',
    },
    bottomData:{
      param1:'',
      param2:'',
      param3:'',
    },
    HGzh:{
      param1:'',
      param2:'',
      param3:'',
    },
    Hwx:{
      param1:'',
      param2:'',
      param3:'',
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
