<template>
  <div>
    <el-dialog  :lock-scroll="false"  :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="true" :show-close="false"  @close="Hclose">
      <div slot="title" class="dialog-footer">
        <el-row>
          <el-col :span="22" style="font-size: 18px;font-weight: bold">企业合作意向表</el-col>
          <el-col :span="2" style="text-align: right;font-size: 18px;cursor: pointer"><i class="el-icon-close" @click="Hclose"></i></el-col>
        </el-row>
      </div>
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="企业名称" :label-width="formLabelWidth" prop="businessName">
          <el-input v-model="form.businessName" autocomplete="off" placeholder="请填写企业名称"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="headName">
          <el-input v-model="form.headName" autocomplete="off" placeholder="请填写对接人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="formLabelWidth" prop="phoneNum">
          <el-input   oninput="value = value.replace(/[^0-9]/g,'' )" placeholder="请填写对接人电话" v-model="form.phoneNum" maxlength="11" class="input-with-select">
            <el-select v-model="form.phoneCode" slot="prepend" placeholder="请选择">
              <el-option label="+86" value="1"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="资讯内容" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请填写资讯内容"
            v-model="form.remark">
          </el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <div style="text-align: center">
          <el-button type="primary" :loading="HFlage" style="min-width: 300px;background: rgb(5,66,239)" @click="gogogo">提 交</el-button>
        </div>

      </div>
    </el-dialog>
    <dragVerifyImgChip  ref="dragImgChip" @selected="dragCallBack" />

  </div>

</template>

<script>
import {apply} from "@/api/home";
import {Message} from "element-ui";
import dragVerifyImgChip from "@/components/dragImgChip/index.vue";

export default {
  name: "partner",
  props:['dialogFormVisible'],
  components:{dragVerifyImgChip},
  data(){
    return{
      form: {
        businessName:'',
        headName:'',
        phoneCode:'+86',
        phoneNum:'',
        remark:''
      },
      rules: {
        phoneNum: [
          { required: true, message: '请填写对接人电话', trigger: 'blur' },
          { min: 11, max: 11, message: '长度在 11 个字符', trigger: 'blur' }
        ],
        businessName: [
          { required: true, message: '请填写企业名称', trigger: 'blur' }
        ],
        headName: [
          { required: true, message: '请填写对接人姓名', trigger: 'blur' }
        ],
      },
      select:'1',
      formLabelWidth: '120px',
      HFlage:false,

    }
  },
  methods:{
    Hclose(){
      // this.form={}
      this.$refs.ruleForm.resetFields();
      this.form.phoneCode='+86'
      this.$emit('closeDialogFormVisible',false)
    },
    gogogo(){
      console.log(this.$refs,'vv')
      let that=this
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
            console.log(that.$refs,'xcccc')
          that.$refs.dragImgChip.toggle()

        } else {
          return false;
        }
      });
    },
    dragCallBack(e){
      let that=this
      that.HFlage=true
      apply(this.form).then(res=>{
        that.HFlage=false
        Message({
          message: res.message,
          type: 'success',
          duration: 5 * 1000
        })
        that.Hclose()
      }).catch(err=>{
        that.HFlage=false
        // that.Hclose()
      })
    },

  }
}
</script>

<style scoped>
::v-deep .el-input-group__prepend{
  width: 50px;
}
</style>
